<template>
  <div class="favq clearfix">
    <headtopsystemgl></headtopsystemgl>
    <div class="enterprise clearfix">
      <div class="left">
        <systemglleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">组织架构管理</h1>
          <el-popover
            placement="bottom"
            width="320"
            trigger="click"
            ref="bianji"
          >
            <ul class="xinzeng_xiajitc">
              <li>
                <span>组织机构名</span>
                <el-input v-model="zzname" placeholder="请输入"></el-input>
              </li>
              <li>
                <span>上级机构</span>
                <el-select
                  placeholder="请选择"
                  v-model="industry"
                  @change="labelchange($event, list1, 1)"
                >
                  <el-option
                    v-for="(item, index) in list1"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </li>
              <li>
                <span>机构负责人</span>
                <el-input v-model="zzfzr" placeholder="请输入"></el-input>
              </li>
              <li>
                <span>负责人电话</span>
                <el-input v-model="zzfzrdh" placeholder="请输入"></el-input>
              </li>
            </ul>
            <div class="tc_tit">统筹机构</div>
            <ul class="xinzeng_jgoutc">
              <li v-for="(i, index) in list2" :key="index">
                <el-checkbox
                  ref="checkbox"
                  @change="handleCheckAllChange(i.value)"
                ></el-checkbox>
                {{ i.label }}
              </li>
            </ul>
            <div class="btn">
              <el-button id="but2" @click="close()">取消</el-button>
              <el-button id="but1" @click="addOrg">确定</el-button>
            </div>

            <el-button class="zuzhi_btns" slot="reference">+新增</el-button>
          </el-popover>
        </div>

        <div class="project_main">
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr class="tit_bg">
                <td style="width: 28%">组织机构层次</td>
                <td style="width: 10%">部门人数</td>
                <td style="width: 10%">部门负责人</td>
                <td style="width: 15%">负责人电话</td>
                <td>操作</td>
              </tr>
            </table>

            <div class="box clearfix">
              <ul
                class="clearfix"
                @click="oneshow(item.id, item.cate)"
                v-for="(item, index) in listdata"
                :key="index"
              >
                <li style="width: 28%; text-align: left; padding-left: 20px">
                  <img
                    :class="oneid == item.id ? 'rotate180' : ''"
                    src="@/assets/systemgl/xiajiantou.png"
                    alt
                  />
                  {{ item.name }}
                </li>
                <li style="width: 10%">{{ item.pnums }}</li>
                <li style="width: 10%">{{ item.charge }}</li>
                <li style="width: 15%">{{ item.charge_phone }}</li>
                <li style="width: 37%">
                  <span
                    class="lvzi_btn"
                    @click.stop="
                      $router.push({
                        name: 'systemglzuzhibianj2',
                        query: { id: item.id, cate: item.cate },
                      })
                    "
                    >编辑</span
                  >
                  <el-popover
                    placement="bottom"
                    width="320"
                    trigger="click"
                    :ref="'popbj' + item.id"
                    @show="ifoneid(item.id)"
                    @hide="ifoneid(item.id)"
                  >
                    <ul class="xinzeng_xiajitc">
                      <li>
                        <span>部门名称</span>
                        <el-input
                          v-model="name"
                          placeholder="请输入"
                        ></el-input>
                      </li>
                      <li>
                        <span>部门人数</span>
                        <el-input
                          v-model="pnums"
                          placeholder="请输入"
                        ></el-input>
                      </li>
                      <li>
                        <span>部门负责人</span>
                        <el-input
                          v-model="charge"
                          placeholder="请输入"
                        ></el-input>
                      </li>
                      <li>
                        <span>负责人电话</span>
                        <el-input
                          v-model="charge_phone"
                          placeholder="请输入"
                        ></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <el-button id="but2" @click.stop="bjclose(item.id)"
                        >取消</el-button
                      >
                      <el-button
                        id="but1"
                        @click.stop="addSub(item.id, item.cate)"
                        >确定</el-button
                      >
                    </div>
                    <el-button class="chengzi_btn" slot="reference"
                      >新增下级部门</el-button
                    >
                  </el-popover>
                  <!-- <el-button class="huangzi_btn" slot="reference">删除</el-button> -->
                  <el-popover
                    placement="bottom"
                    width="220"
                    trigger="click"
                    @show="ifoneid(item.id)"
                    @hide="ifoneid(item.id)"
                    :ref="'popsc' + item.id"
                  >
                    <p class="delt_p">确定删除此项目？</p>
                    <div class="btn">
                      <el-button id="but2" @click="scclose(item.id)"
                        >取消</el-button
                      >
                      <el-button
                        id="but1"
                        @click="deleteOrg(item.id, item.cate)"
                        >确定</el-button
                      >
                    </div>
                    <el-button class="huangzi_btn" slot="reference"
                      >删除</el-button
                    >
                  </el-popover>
                </li>
                <template v-if="oneid == item.id">
                  <ul
                    class="clearfix"
                    v-for="(itemtwo, indextwo) in listdata2"
                    :key="indextwo"
                    @click.stop="twoshow(itemtwo.id, itemtwo.cate)"
                  >
                    <li style="width: 28%; text-align: left">
                      <img
                        :class="twoid == itemtwo.id ? 'rotate180' : ''"
                        src="@/assets/systemgl/xiajiantou.png"
                        alt
                      />
                      {{ itemtwo.name }}
                    </li>
                    <li style="width: 10%">{{ itemtwo.pnums }}</li>
                    <li style="width: 10%">{{ itemtwo.charge }}</li>
                    <li style="width: 15%">{{ itemtwo.charge_phone }}</li>
                    <li style="width: 37%">
                      <span
                        class="lvzi_btn"
                        @click.stop="
                          $router.push({
                            name: 'systemglzuzhibianj',
                            query: { id: itemtwo.id, cate: itemtwo.cate },
                          })
                        "
                        >编辑</span
                      >
                      <el-popover
                        placement="bottom"
                        width="320"
                        trigger="click"
                        :ref="'popbj2' + itemtwo.id"
                        @show="iftwoid(itemtwo.id)"
                        @hide="iftwoid(itemtwo.id)"
                      >
                        <ul class="xinzeng_xiajitc">
                          <li>
                            <span>部门名称</span>
                            <el-input
                              v-model="name"
                              placeholder="请输入"
                            ></el-input>
                          </li>
                          <li>
                            <span>部门人数</span>
                            <el-input
                              v-model="pnums"
                              placeholder="请输入"
                            ></el-input>
                          </li>
                          <li>
                            <span>部门负责人</span>
                            <el-input
                              v-model="charge"
                              placeholder="请输入"
                            ></el-input>
                          </li>
                          <li>
                            <span>负责人电话</span>
                            <el-input
                              v-model="charge_phone"
                              placeholder="请输入"
                            ></el-input>
                          </li>
                        </ul>
                        <div class="btn">
                          <el-button
                            id="but2"
                            @click.stop="bjclose2(itemtwo.id)"
                            >取消</el-button
                          >
                          <el-button
                            id="but1"
                            @click.stop="addSub(itemtwo.id, itemtwo.cate)"
                            >确定</el-button
                          >
                        </div>
                        <el-button class="chengzi_btn" slot="reference"
                          >新增下级部门</el-button
                        >
                      </el-popover>
                      <el-popover
                        placement="bottom"
                        width="220"
                        trigger="click"
                        @show="iftwoid(itemtwo.id)"
                        @hide="iftwoid(itemtwo.id)"
                        :ref="'popsc2' + itemtwo.id"
                      >
                        <p class="delt_p">
                          popsc2{{ item.id }}确定删除此项目？
                        </p>
                        <div class="btn">
                          <el-button
                            id="but2"
                            @click="scclose(itemtwo.id, itemtwo.cate)"
                            >取消</el-button
                          >
                          <el-button
                            id="but1"
                            @click="deleteOrg(itemtwo.id, itemtwo.cate,item.id)"
                            >确定</el-button
                          >
                        </div>
                        <el-button class="huangzi_btn" slot="reference"
                          >删除</el-button
                        >
                      </el-popover>
                    </li>
                    <template v-if="twoid == itemtwo.id">
                      <ul
                        class="clearfix"
                        v-for="(itemthr, indexthr) in listdata3"
                        :key="indexthr"
                        @click.stop="thrshow()"
                      >
                        <li style="width: 20%; text-align: left">
                          {{ itemthr.name }}
                        </li>
                        <li style="width: 13%">{{ itemthr.pnums }}</li>
                        <li style="width: 15%">{{ itemthr.charge }}</li>
                        <li style="width: 15%">{{ itemthr.charge_phone }}</li>
                        <li style="width: 37%">
                          <span
                            class="lvzi_btn"
                            @click.stop="
                              $router.push({
                                name: 'systemglzuzhibianj',
                                query: { id: itemthr.id, cate: itemthr.cate },
                              })
                            "
                            >编辑</span
                          >
                          <el-popover
                            placement="bottom"
                            width="220"
                            trigger="click"
                            :ref="'popsc3' + itemthr.id"
                          >
                            <p class="delt_p">确定删除此项目？</p>
                            <div class="btn">
                              <el-button
                                id="but2"
                                @click="scclose(itemthr.id, itemthr.cate)"
                                >取消</el-button
                              >
                              <el-button
                                id="but1"
                                @click="deleteOrg(itemthr.id, itemthr.cate)"
                                >确定</el-button
                              >
                            </div>
                            <el-button class="huangzi_btn" slot="reference"
                              >删除</el-button
                            >
                          </el-popover>
                        </li>
                      </ul>
                    </template>
                  </ul>
                </template>
              </ul>
            </div>
          </div>

          <!-- <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  uadOrg,
  addOrg,
  organization,
  sectionGroup,
  addSub,
  deleteOrg,
  downOrg,
} from "@/api/index";
export default {
  data() {
    return {
      zzname: "",
      zzfzr: "",
      zzfzrdh: "",
      industry: "",
      list1: "",
      list2: "",
      checkedCities: "",
      arr: [],
      statu: "",
      show: false,
      page: 1,
      perpage: 10,
      listdata: [],
      listdata2: [],
      listdata3: [],
      oneid: -1,
      twoid: -1,
      name: "", // # 新增机构或者部门名称
      pnums: "", //   # 机构人数
      charge: "", //  # 机构负责人
      charge_phone: "", // # 负责人电话
    };
  },
  created() {
    this.uadOrg();
    this.downOrg();
    this.organization();
  },
  methods: {
    // 一级下拉
    oneshow(id, cate) {
      if (this.oneid == id) {
        this.oneid = -1;
      } else {
        this.oneid = id;
      }
      this.onetwo(id, cate);
    },
    twoshow(id, cate) {
      if (this.twoid == id) {
        this.twoid = -1;
      } else {
        this.twoid = id;
      }
      this.onetwo(id, cate);
    },
    thrshow() {},
    ifoneid(id) {
      if (this.oneid == id) {
        this.oneid = -1;
      } else {
        this.oneid = id;
      }
    },
    iftwoid(id) {
      if (this.twoid == id) {
        this.twoid = -1;
      } else {
        this.twoid = id;
      }
    },
    async onetwo(id, cate) {
      const res = await sectionGroup({
        id: id,
        cate: cate,
      });
      if (cate == 1) {
        this.listdata2 = res;
      } else {
        this.listdata3 = res;
      }
    },
    async addOrg() {
      if (this.zzname == "") {
        this.$message({
          message: "请输入机构名称",
          type: "error",
        });
        return false;
      }
      const res = await addOrg({
        name: this.zzname,
        forg: this.statu,
        charge: this.zzfzr,
        charge_phone: this.zzfzrdh,
        sublist: this.arr,
      });
      let refName = "bianji";
      this.$refs[refName].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.organization();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async organization() {
      const res = await organization({
        page: this.page,
        perpage: this.perpage,
      });
      this.listdata = res.df;
    },
    close() {
      let refName = "bianji";
      this.$refs[refName].doClose();
    },
    async addSub(id, cate) {
      if (
        this.name == "" ||
        this.pnums == "" ||
        this.charge == "" ||
        this.charge_phone == ""
      ) {
        this.$message({
          message: "请输入全部内容",
          type: "error",
        });
        return false;
      }
      if (cate == 1) {
        this.ifoneid(id);
      } else {
        this.iftwoid(id);
      }

      const res = await addSub({
        id: id, //   当前数据的id
        cate: cate, //  # 当前数据的cate
        name: this.name, // # 新增机构或者部门名称
        pnums: this.pnums, //   # 机构人数
        charge: this.charge, //  # 机构负责人
        charge_phone: this.charge_phone, // # 负责人电话
      });
      if (cate == 1) {
        let refName = "popbj" + id;
        this.$refs[refName][0].doClose();
      } else {
        let refName2 = "popbj2" + id;
        this.$refs[refName2][0].doClose();
      }

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.onetwo(id, cate);
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    bjclose(id) {
      this.ifoneid(id);
      let refName = "popbj" + id;
      this.$refs[refName][0].doClose();
    },
    bjclose2(id) {
      this.iftwoid(id);
      let refName2 = "popbj2" + id;
      this.$refs[refName2][0].doClose();
    },
    bjclose3(id) {
      let refName2 = "popbj3" + id;
      this.$refs[refName2][0].doClose();
    },
    scclose(id, cate) {
      console.log(id, cate);
      if (cate == 2) {
        this.iftwoid(id);
        let refName2 = "popsc2" + id;
        this.$refs[refName2][0].doClose();
      } else if (cate == 3) {
        let refName3 = "popsc3" + id;
        // let refName3 = "popsc3" + id;
        this.$refs[refName3][0].doClose();
      } else {
        this.ifoneid(id);
        let refName = "popsc" + id;
        this.$refs[refName][0].doClose();
      }
    },
    async uadOrg() {
      const res = await uadOrg({});
      this.list1 = res;
    },
    async downOrg() {
      const res = await downOrg({ id: this.statu });
      this.list2 = res;
    },
    // deleteOrg
    async deleteOrg(id, cate,f_id) {
      const res = await deleteOrg({
        id: id,
        cate: cate,
      });
      if (cate == 1) {
        let refName = "popsc" + id;
        this.$refs[refName][0].doClose();
      } else if (cate == 2) {
        let refName2 = "popsc2" + id;
        this.$refs[refName2][0].doClose();
      } else {
        let refName3 = "popsc3" + id;
        this.$refs[refName3][0].doClose();
      }

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        if (cate == 2) {
          this.onetwo(f_id, 1);
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.organization();
    },
    handleCheckAllChange(e) {
      this.arr.push(e);
    },

    labelchange(e, objx, num) {
      switch (num) {
        case 1:
          this.statu = e;
          this.downOrg();
          break;
        case 2:
          this.organization = e;
          this.fieldt();
          break;
        case 3:
          this.stageid = e;
          break;
        case 4:
          this.rank = e;
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.zuzhi_btns {
  display: inline-block;
  float: left;
  padding: 0 22px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 2% 0 1% 0;
  cursor: pointer;
}

.zhaosh_tit {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}
.soso_box {
  padding: 22px 10px 0 22px;
  overflow: hidden;
  zoom: 1;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 15px);
  min-height: 89vh;
  float: right;
  margin-left: 10px;

  table td {
    font-size: 14px;
    line-height: 44px;
  }
  .project_main {
    padding: 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr.tit_bg {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }
      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }
      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }
    .project_tables td:first-child {
      text-align: left;
      padding-left: 20px;
    }
    .project_tables td {
      border-bottom: 1px solid #eee;
    }
    .project_tables td img {
      margin-right: 5px;
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}

.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.btn {
  text-align: center;
  margin: 20px 0 15px 0;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.branch {
  overflow: auto;
  ul {
    height: auto;
  }
}
.el-collapse-item {
  padding: 0 10px;
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}

.xinzeng_xiajitc {
  margin-top: 5px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li {
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li span {
  display: block;
  width: 82px;
  float: left;
  height: 30px;
  line-height: 30px;
}
.xinzeng_xiajitc li .el-input {
  width: 206px;
}
.xinzeng_xiajitc li .el-select {
  width: 206px;
}
.tc_tit {
  margin-bottom: 5px;
}
.xinzeng_jgoutc {
  background: #fafcff;
  padding: 10px;
  line-height: 28px;
  border: 1px solid #dddddd;
  height: 90px;
  overflow-y: auto;
}
.xinzeng_jgoutc .el-checkbox {
  margin-right: 5px;
}
.delt_p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 25px;
}

/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
/deep/.el-collapse-item__arrow {
  display: none;
}
/deep/.el-button {
  border: 0;
}
/deep/.el-input {
  width: 100%;
  height: 30px;
  input {
    border-radius: 0;
    width: 100%;
    height: 30px;
    color: #333;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
  /deep/.el-icon-circle-close {
    background: url("../../assets/merchantsradar/cuo.png") 50% 50% no-repeat;
  }
  .el-icon-circle-close:before {
    content: "";
  }
}
/deep/.el-select {
  width: 70%;
  height: 30px;
  input {
    width: 100%;
    height: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  /deep/.el-input__icon {
    line-height: 30px !important;
  }
}
/deep/ .el-checkbox__label {
  display: none;
}
/deep/.el-textarea__inner:focus {
  border-color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fe6a00;
  border-color: #fe6a00;
}
.box {
  width: 100%;
  > ul {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    transition: all 0.25s;
    height: auto;
    cursor: pointer;
    li {
      float: left;
      height: 44px;
    }
    img {
      margin-right: 3px;
    }
    &:nth-child(2n) {
      background-color: #f3f6fb;
    }
    > ul {
      padding-left: 40px;

      > ul {
        padding-left: 20px;
      }
    }
    ul:last-child {
      border: none;
    }
  }
  .rotate180 {
    transition: transform 0.5s;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -moz-transform: rotate(180deg);
    /* Firefox */
    -webkit-transform: rotate(180deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(180deg);
    /* Opera */
  }
}
</style>
